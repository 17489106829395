











































import Component from "vue-class-component";
import { Vue, Watch } from "vue-property-decorator";
import { DataFlowFullTable } from "@/healthcheck/model/DataFlowFullTable";

@Component
export default class HealthCheckDataFlowView extends Vue {
  readonly dataFlowHeaders = [
    {
      text: this.$lang("dataFlowTable.headers.dataFlow"),
      align: "start",
      value: "dataFlow",
    },
    {
      text: this.$lang("dataFlowTable.headers.metric"),
      value: "metric",
    },
    {
      text: this.$lang("dataFlowTable.headers.name"),
      value: "name",
    },
    {
      text: this.$lang("dataFlowTable.headers.lastExecutedJob"),
      value: "lastExecutedJob",
    },
    {
      text: this.$lang("dataFlowTable.headers.updatedAt"),
      value: "updatedAt",
    },
  ];
  readonly timePeriod = 14;

  search = "";

  get isDataFlowTableLoading(): boolean {
    return this.$store.state.healthCheckStore.isDataFlowLoading;
  }

  get isDataFlowTableExist(): boolean {
    return this.dataFlowTable.length !== 0;
  }

  get dataFlowTable(): Array<DataFlowFullTable> {
    return this.$store.state.healthCheckStore.dataFlowTables;
  }

  get appId(): string {
    return this.$route.params.appId;
  }

  get applicationName(): string {
    return (
      this.$store.getters.applicationsById.get(this.appId)?.name ?? this.appId
    );
  }

  @Watch("appId", { immediate: true })
  watchApplicationId() {
    this.$store.dispatch("getDataFlowFullData", {
      applicationId: this.appId,
      timePeriodDays: this.timePeriod,
    });
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("dataFlowTable.title")
    );
  }
}
